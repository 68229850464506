<template>
    <modal ref="modalAgregarCuenta" titulo="Editar cuenta de pasarela de pago - Mercado pago" tamano="modal-lg" no-aceptar adicional="Guardar" @adicional="editar">
        <ValidationObserver ref="form" class="w-100">
            <div class="row mx-0 justify-center">
                <div class="col-6">
                    <ValidationProvider v-slot="{errors}" rules="required" name="nombre">
                        <p class="f-12 text-general pl-3 f-500 mt-3">Nombre de la cuenta</p>
                        <el-input v-model="model.nombre" size="small" placeholder="Nombre" class="w-100" />
                        <span class="text-danger w-100 f-11 pl-3"> {{ errors[0] }} </span>
                    </ValidationProvider>
                    <ValidationProvider v-slot="{errors}" rules="required" name="public key">
                        <p class="f-12 text-general pl-3 f-500 mt-3">public key</p>
                        <el-input v-model="model.publickKey" size="small" placeholder="public key" class="w-100" />
                        <span class="text-danger w-100 f-11 pl-3"> {{ errors[0] }} </span>
                    </ValidationProvider>
                    <ValidationProvider v-slot="{errors}" rules="required" name="acces_token">
                        <p class="f-12 text-general pl-3 f-500 mt-3">Access token</p>
                        <el-input v-model="model.accessToken" size="small" placeholder="Acces token" class="w-100" />
                        <span class="text-danger w-100 f-11 pl-3"> {{ errors[0] }} </span>
                    </ValidationProvider>
                    <ValidationProvider v-slot="{errors}" rules="required" name="descripcion">
                        <p class="f-12 text-general pl-3 f-500 mt-4">Descripción</p>
                        <el-input v-model="model.descripcion" placeholder="Descripción de la Cuenta" type="textarea" :rows="5" class="w-100" />x
                        <span class="text-danger w-100 f-11 pl-3"> {{ errors[0] }} </span>
                    </ValidationProvider>
                </div>
                <div class="col-6">
					<ValidationProvider v-slot="{ errors }" rules="required|max:30" name="Código de la empresa">
						<p class="f-12 text-general pl-3 f-500 mt-3">Código de la empresa </p>
						<el-input v-model="model.clientId" size="small" placeholder="Código de la empresa" class="w-100" />
						<span class="text-danger w-100 f-11 pl-3"> {{ errors[0] }} </span>
					</ValidationProvider>
					<ValidationProvider v-slot="{ errors }" rules="required|max:120" name="Client secret">
						<p class="f-12 text-general pl-3 f-500 mt-3">Client secret</p>
						<el-input v-model="model.clientSecret" size="small" placeholder="Client secret" class="w-100" />
						<span class="text-danger w-100 f-11 pl-3"> {{ errors[0] }} </span>
					</ValidationProvider>
                    <ValidationProvider v-slot="{errors}" rules="required" name="moneda">
                        <p class="f-12 text-general f-500 pl-3 mt-3">Moneda</p>
                        <el-select v-model="model.idm_moneda" size="small" placeholder="seleccionar" class="w-100">
                            <el-option
                            v-for="item in selectMonedas"
                            :key="item.id"
                            :label="item.nombre"
                            :value="item.id"
                            />
                        </el-select>
                        <span class="text-danger w-100 f-11 pl-3"> {{ errors[0] }} </span>
                    </ValidationProvider>
                    <p class="f-12 text-general f-500 pl-3 mt-4">Seleccionar Cedis</p>
                    <el-select v-model="id_cedis" size="small" placeholder="seleccionar" class="w-100" @change="addCedis">
                        <el-option
                        v-for="item in selectCedis"
                        :key="item.id"
                        :label="item.nombre"
                        :value="item.id"
                        :disabled="item.disabled"
                        />
                    </el-select>
                    <div v-for="(c,key) in model.cedis" :key="key" class="row mx-0 bg-light-f5 border align-items-center pl-2 mt-2 br-5 f-14" style="height:32px;">
                     {{c.nombre}}
                         <i class="icon-cancel ml-auto mr-2 text-general f-16 cr-pointer" @click="removerCedis(c.id_cedis)"/>
                    </div>
                </div>
            </div>
        </ValidationObserver>
    </modal>
</template>

<script>
import Pagos from "~/services/pasarela-tesoreria/mercado-pago"
export default {
    data(){
        return{
            id_cedis: '',
            model:{
                nombre: '',
				descripcion: '',
				publickKey: '',
				clientId: '',
				clientSecret: '',
				accessToken: '',
				cedis: [],
				idm_moneda: ''
            },
            cedisAgregados:[],
            id_cuenta:null
        }
    },
    computed:{
        selectMonedas(){
            return this.monedas.filter(o=>o.sigla !== 'EUR')
        },
        selectCedis(){
            return this.cedis_calculo.filter(o=>o.id != 0)
        }
    },
    methods: {
        toggle(item){
            this.id_cuenta = item.id
            this.model = {
				nombre: item.nombre,
				descripcion: item.descripcion,
				publickKey: item.publick_key,
				clientId: item.client_id,
				clientSecret: item.client_secret,
				accessToken: item.acces_token,
				cedis: item.cedis,
				idm_moneda:item.idm_moneda,
            },
            this.cedisAgregados = []
            this.listarCedis(item.cedis)

            this.$refs.modalAgregarCuenta.toggle();
        },
        async listarCedis(item){
            try {
				this.selectCedis.map( e => e.disabled = false)
                for (const c of item){
                    let cedi = this.selectCedis.find(o=>o.id == c.id_cedis);
                    if(cedi){
                        cedi.disabled = true
                    }
                }
            } catch (e){
                this.error_catch(e)
            }
        },
        addCedis(id){
            let c = this.selectCedis.find(o=>o.id === id)
            c.disabled = true
			c.id_cedis = c.id
            this.model.cedis.push(c)
            this.id_cedis = null
        },
        removerCedis(id){
			try {
				this.selectCedis.find(o=>o.id === id).disabled = false;
				const idx = this.model.cedis.findIndex( (e) => e.id_cedis == id)
				this.model.cedis.splice(idx, 1)
			} catch (e) {
				return this.error_catch(e)
			}
        },
        async editar(){
            try {

                const valid = await this.$refs.form.validate()
                if(!valid) return

                let cedis = this.model.cedis.map(element => element.id_cedis)
				this.model.idsCedis = cedis;

                const {data} = await Pagos.editCuenta(this.id_cuenta, this.model)
                this.notificacion('',data.mensaje,'success')
                this.$emit('update')
                this.$refs.modalAgregarCuenta.toggle();

            } catch (e){
                this.error_catch(e)
            }
        }
    }
}
</script>
